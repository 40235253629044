.sponsorList {
  margin-top: 30px;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
}

#Silver {
  background-color: silver;
}
#Platinum {
  background-color: #c7eff1;
}
#Gold {
  background-color: goldenrod;
}
#Bronze {
  background-color: #d8b65d;
}

.platinum {
  border-radius: 10px;
  padding: 1rem;
  width: 16.5rem;
  background-color: #c7eff1;
}

.gold {
  width: 13rem;
  background-color: goldenrod;
  padding: 10px;
}

.silver {
  border-radius: 10px;
  width: 9.5rem;
  background-color: silver;
  padding: 10px;
}

.bronze {
  border-radius: 5px;
  width: 7.9rem;
  padding: 5px;
  background-color: #f8f8f8;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .platinum {
    border-radius: 5px;
    width: 10rem;
  }

  .gold {
    border-radius: 5px;
    width: 8rem;
  }

  .silver {
    border-radius: 5px;
    width: 6rem;
  }

  .bronze {
    border-radius: 5px;
    width: 5rem;
  }
}

@media screen and (max-width: 767px) {
  .platinum {
    border-radius: 5px;
    padding: 5px;
    width: 5rem;
  }

  .gold {
    border-radius: 5px;
    width: 4.4rem;
    padding: 5px;
  }

  .silver {
    border-radius: 5px;
    width: 3.8rem;
    padding: 5px;
  }

  .bronze {
    border-radius: 3px;
    width: 3rem;
  }
}
