/* Style for banner idepreneurs v2 */
/* Copy and Paste this code */

.slick-list,
.slick-slider {
  height: 100%;
}
.site-page-carousel,
.site-page-carousel-inner {
  aspect-ratio: 8/3;
  height: auto !important;
}
.site-page-carousel {
  width: 100%;
  position: relative;
  margin-top: 75px;
}
.site-page-carousel-inner {
  width: 100%;
}
.img-banner {
  width: 100%;
  z-index: 5;
  position: relative;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
}

/* Dost Carousel */
.slick-dots {
  display: flex !important;
  flex-direction: column;
  right: 0;
  top: 50%;
  width: unset !important;
}
.slick-custom-dots {
  width: 10px;
  background-color: #CE9963;
  border-radius: 50%;
  height: 10px;
}
.slick-active .slick-custom-dots::before {
  content: "";
  width: 20px;
  display: block;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid #CE9963;
  top: -5.1px;
  left: -5px;
}

/* Container Text */
.container-text {
  position: absolute;
  top: 50%;
  width: 500px;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
}
.container-text h1{
  font-weight: bolder;
}
.left {
  left: 15%;
}
.right {
  right: 15%;
}

/* Animasi */
.slick-active button {
  -webkit-animation: fade-in-bottom 1s 0.8s both;
  animation: fade-in-bottom 1s 0.8s both;
}
.slick-active p {
  -webkit-animation: fade-in-bottom 1s 0.4s both;
  animation: fade-in-bottom 1s 0.4s both;
}
.slick-active h1 {
  -webkit-animation: fade-in-bottom 1s 0.2s both;
  animation: fade-in-bottom 1s 0.2s both;
}
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* Tablet Version */
@media screen and (max-width: 992px) and (min-width: 768px) {
  .site-page-carousel,
  .site-page-carousel-inner {
    height: 530px;
  }
  .container-text h1 {
    font-size: 2rem;
  }
  .slick-active p {
    font-size: 0.8rem;
  }
}

/* Mobile Version */
@media screen and (max-width: 767px) {
  .site-page-carousel,
  .site-page-carousel-inner {
    height: 400px;
  }
  .container-text{
    width: 300px;
    margin: auto;
  }
  .container-text h1 {
    font-size: 1.5rem;
  }
  .slick-active p {
    font-size: 0.7rem;
  }
  .left {
    left: 10%;
  }
  .right {
    right: 10%;
  }
}
@media screen and (max-width: 480px) {
  .container-text h1 {
    font-size: 1.3rem;
  }
  .container-text {
    width: 225px;
  }
  .slick-active p {
    font-size: 0.6rem;
  }
}