.bg-image-ourProgram {
  padding: 75px;
  padding-left: 150px;
  padding-right: 150px;
  background-image: url("/public/Assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 80vh;
  width: 100%;
}

.form-input {
  width: 100%;
  padding-top: 50px;
}
.filter-form {
  width: inherit;
  height: auto;
  padding: 30px 15px;
  background-color: whitesmoke;
  border-radius: 5px;
}
.filter-title {
  margin-bottom: 15px;
}
.filter-btn.add-new-btn {
  width: 100%;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.input-filter {
  background-color: white;
  border-radius: 8px;
}
.form-input button.add-new-btn {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 50px;
  outline: none !important;
}
.input-filter button {
  outline: none;
}
.input-filter
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]
  .MuiAutocomplete-input {
  padding: 0 4px;
  padding-bottom: 20px;
}
.input-filter .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  padding-top: 20px;
}
.input-filter .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 5px) scale(0.75);
}
.input-filter .MuiFilledInput-underline:hover::before,
.input-filter .MuiFilledInput-underline:active::before,
.input-filter .MuiFilledInput-underline::before,
.input-filter .MuiFilledInput-underline:after {
  border: none !important;
  content: none;
}
.dropdown-filter .input-filter .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
  padding-top: 7px;
  padding-bottom: 13px;
}

.event-container {
  padding: 20px 0;
}

@media (min-width: 480px) and (max-width: 1200px) {
  .bg-image-ourProgram {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media screen and (max-width: 480px) {
  .bg-image-ourProgram {
    padding-left: 50px;
    padding-right: 50px;
  }
}