/* Modal Voting */
.voting-modal .modal-header .modal-title {
    font-size: 1.4rem;
}
.btn.submit-vote-btn {
    min-width: 80px;
    background-color: #ce9963;
    border-color: #ce9963;
    margin-right: auto;
}
.btn.submit-vote-btn:hover {
    background-color: #daa774;
}