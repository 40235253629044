.title {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.jabatan {
  color: #ce9963;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pembinaActive {
  border-radius: 5px;
  aspect-ratio: 2/3;
  object-fit: cover;
}

.pembina {
  border-radius: 5px;
  opacity: 0.4;
  aspect-ratio: 2/3;
  object-fit: cover;
}
.pembina:hover {
  border-radius: 5px;
  opacity: 1;
}

.pembinaWrap {
  background-color: black;
  width: auto;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.pembinaWrap:hover {
  cursor: pointer;
}

.pembinaContent {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.pembinaDesc {
  color: white;
}

.flip-card {
  background-color: transparent;
  width: 190px;
  height: 190px;
  margin-bottom: 2rem;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.pengurusActive-back {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
}

.pengurusActive-front {
  background-color: #bbb;
  color: black;
  border-radius: 5px;
}
.pengurusActive-front img {
  width: 190px;
  height: 190px;
  object-fit: contain;
}

.pengurusActive-back {
  color: white;
  transform: rotateY(180deg);
  justify-content: flex-end;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
  background-color: #00000090;
  flex-direction: column;
  top: 0;
  display: flex;
  backface-visibility: hidden;
}

h3.namaPembina {
  color: #ce9963;
  font-weight: bold;
}

.descMobile {
  display: none;
}
.namaExe {
  font-weight: bolder;
  color: #ce9963 !important;
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  .namaPembina {
    font-size: 1.5rem;
  }
  .jabatan {
    font-size: 1.2rem;
  }

  .pembinaDesc {
    font-size: 1rem;
  }
  .title {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .flip-card {
    width: 125px;
    height: 125px;
  }
  .pengurusActive-front img {
    width: 125px;
    height: 125px;
  }

  .descMobile {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    font-size: 0.7rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
    padding-right: 40px;
  }
  .pengurusActive-back {
    display: none;
    transform: none;
  }
  .pengurusActive-front,
  .pengurusActive-back {
    transform: none;
  }
  .flip-card:hover .flip-card-inner {
    transform: none;
  }
}

@media screen and (max-width: 767px) {
  .namaPembina {
    font-size: 1rem;
  }

  .jabatan {
    font-size: 0.8rem;
  }

  .pembinaDesc {
    font-size: 0.7rem;
  }

  .pembinaContent {
    margin-bottom: 0.5rem;
  }

  .title {
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
  }

  .flip-card {
    width: 125px;
    height: 125px;
    margin-left: auto;
    margin-right: auto;
  }
  .pengurusActive-front img {
    width: 125px;
    height: 125px;
  }
  .descMobile {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    font-size: 0.7rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .pengurusActive-back {
    display: none;
    transform: none;
  }
  .pengurusActive-front,
  .pengurusActive-back {
    transform: none;
  }
  .flip-card:hover .flip-card-inner {
    transform: none;
  }
}
