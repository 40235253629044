/*Home News*/

.jumbotron {
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    height: 540px;
    margin-top: 74px;
    background-color: #30302F;
    display: flex;
    flex-direction: column;
}

.imageSlider {
    height: 540px;
    margin-top: 0;
    padding-top: 0;
}

.sliderButton {
    align-self: center;
    margin-top: -7rem;
    background-color: #CE9963;
    border: none;
    font-size: 1.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-weight: bold;
    /* align-items: center;
    display: flex; */
}

.sliderButton:hover {
    background-color: #CE996395;
    border: none
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .jumbotron {
        height: 360px;
    }
    .imageSlider {
        height: 360px;
    }
    .sliderButton {
        margin-top: -5rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 767px) {
    .jumbotron {
        height: 180px;
    }
    .imageSlider {
        height: 180px;
        z-index: 999999;
    }
    .carousel-control-prev, .carousel-control-next{
        display: none;
    }
    .carousel-indicators{
        bottom: 0;
    }
    .sliderButton {
        margin-top: -3rem;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 0.5rem;
        display: none;
    }

}

/*event container*/

.eventHomeContainer {
    background-image: url("/public/Assets/bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
}

.homeEventContainer {
    height: auto;
}

.contentSpacing {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.homeEventImgContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-left: auto;
}

.homeEventImage {
    margin-left: auto;
    margin-right: 0;
    width: '100%';
    height: 22rem;
    border-radius: 5px;
}

.homeEventList {
    margin-top: 2rem;
    display: flex;
}

.eventCard {
    flex: 1;
    background-color: #30302F;
    color: white;
    cursor: pointer;
    margin-left: 1rem;
    margin-right: 1rem;
}

.eventCard:hover {
    flex: 1;
    background-color: #CE9963;
}

.eventCardActive {
    flex: 1;
    background-color: #CE9963;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    height: auto;
}

.cardBody {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0.5rem;
}

.cardTitle {
    font-size: 1rem;
    margin-bottom: 0;
    height: fit-content;
    text-align: center;
}

.noEvent {
    justify-content: center;
    color: white;
    font-weight: bolder;
    margin-top: 8rem;
    margin-bottom: 8rem;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .contentSpacing {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .homeEventImage {
        height: 17rem;
        border-radius: 5px;
    }
    .cardTitle {
        font-size: 0.7rem;
    }
    .eventCard {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .eventCardActive {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .noEvent>h3 {
        font-size: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .contentSpacing {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .noEvent {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .noEvent>h3 {
        font-size: 1rem;
    }
}

/*anggota home*/

.homeParalax {
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: max-content;
    background-image: url("/public/Assets/paralax1.jpeg");
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.homeParalax>div {
    height: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #00000090;
}

.totalAnggota {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .homeParalax>div {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

@media screen and (max-width: 767px) {
    .homeParalax>div {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .totalAnggota {
        margin-bottom: 2rem;
    }
    .eventHomeContent {
        height: fit-content;
    }
}

/*sponsor*/

.sponsorContainer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #D3D3D3;
}

.deckCard {
    height: 150px;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 80%;
    border: transparent;
    margin: auto;
}

.sponsor {
    width: 6rem;
    height: 6rem;
}

#sponsor a {
    height: auto;
    width: 2rem;
}

#sponsor ol {
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    bottom: -1rem;
    width: 7rem;
}

.deckCard .card {
    align-items: center;
}

#silverSponsor {
    height: auto;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
    width: 90%;
    justify-content: space-around;
    flex-direction: row;
}

#silver {
    background-color: silver;
}

.cardFrame {
    max-width: 7rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.sponsorCarousel {
    height: auto;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .sponsorContainer {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .sponsor {
        width: 4rem;
        height: 4rem;
    }
    #sponsor a {
        width: 1rem;
    }
    #sponsor ol {
        bottom: -0.7rem;
        width: 5rem;
    }
    #silverSponsor {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }
    .cardFrame {
        max-width: 5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
}

@media screen and (max-width: 767px) {
    .sponsorContainer {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .sponsor {
        width: 2rem;
        height: 2rem;
    }
    #sponsor a {
        width: 0.8rem;
    }
    #sponsor ol {
        bottom: -0.5rem;
        width: 2rem;
    }
    #silverSponsor {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .cardFrame {
        max-width: 4rem;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
    }
}