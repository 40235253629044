/*background image*/

.row {
  margin-left: 0;
  margin-right: 0;
}
.bg {
  margin-left: 0;
  margin-right: 0;
  background-color: #30302f;
  justify-content: center;
  align-items: center;
}
.bgImage {
  padding-top: 100px;
  background-image: url("/public/Assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100vh;
}

/*button */

.detailButton {
  margin-top: 1rem;
  background-color: #ce9963;
  padding-left: 1rem;
  padding-right: 1rem;
  border: none;
  font-size: 1rem;
  font-weight: bold;
}
@media screen and (max-width: 992px) and (min-width: 769px) {
  .detailButton {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.8rem;
  }
}

.detailButton:hover {
  background-color: #ce996395;
  border: none;
}

.detailButton:active {
  background-color: #ce996395;
  border: none;
}

.detailButton:focus {
  border: none;
  border-style: none;
  background-color: #ce996395;
}

.btn-primary:not(:disabled):not(.disabled).active:focus {
  background-color: #ce9963;
  border-style: none;
  border-color: #ce9963;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background-color: #ce9963;
  border-style: none;
  border-color: #ce9963;
}

.btn-primary:hover {
  border-color: #ce9963;
}

.btn{
  box-shadow: none !important;
}
/*text*/

h1.judul {
  color: #ce9963;
  font-weight: bold;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  h1.judul {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  h1.judul {
    font-size: 1.5rem;
  }
  .event-link {
      font-size: 0.7rem;
  }
}

h3.judul {
  color: #ce9963;
  font-weight: bold;
  margin-bottom: 1rem;
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  h3.judul {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  h3.judul {
    font-size: 1.2rem;
  }
}

h4.acara {
  color: white;
  font-weight: bold;
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  h4.acara {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  h4.acara {
    font-size: 0.8rem;
  }
}

p.tanggal,
.tanggal {
  color: white;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 993px) {
  p.tanggal,
  .tanggal {
    font-size: 1rem;
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  p.tanggal,
  .tanggal {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  p.tanggal,
  .tanggal {
    font-size: 0.6rem;
  }
}

p.basic,
.basic {
  color: white;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  p.basic,
  .basic {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  p.basic,
  .basic {
    font-size: 0.6rem;
  }
}

/*see More*/

.seeMore {
  border-style: none;
  background-color: transparent;
  color: #ce9963;
  position: relative;
  right: 0;
}

.seeMore:hover {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  .seeMore {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .seeMore {
    font-size: 0.6rem;
  }
}

/* animation */

.animation {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**/

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}


.pastEvent {
  padding-top: 4rem;
  padding-bottom: 4rem;
}


.vision {
  margin-bottom: 25px;
}

.mision {
  margin-top: 25px;
}

.partner {
  padding-top: 50px;
  padding-bottom: 50px;
}

.wavebg {
  margin-bottom: -320px;
  position: fixed;
}

.content {
  padding-left: 150px;
  padding-right: 150px;
}

.registerContent {
  padding-left: 250px;
  padding-right: 250px;
}

.registerButton {
  background-color: #ce9963;
  padding-left: 75px;
  padding-right: 75px;
  border: none;
  font-size: 21px;
  font-weight: bold;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.silverSponsor {
  width: 150px;
  height: 150px;
  margin-top: -20px;
}

.imgHover:hover {
  opacity: 1;
}

.navdrop {
  color: white;
  background-color: black;
}

.navbar-toggler-icon {
  fill: #ce9963;
}


@media screen and (min-width: 993px) {
  .about {
    height: 25rem;
    background-position-y: -5rem;
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  .about {
    height: 20rem;
    background-position-y: -5rem;
  }
}

@media screen and (max-width: 768px) {
  .about {
    height: 20rem;
    background-position-y: -8rem;
  }
}

@media screen and (min-width: 993px) {
  .about {
    height: 25rem;
    background-position-y: -5rem;
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  .about {
    height: 20rem;
    background-position-y: -5rem;
  }
}

@media screen and (max-width: 768px) {
  .about {
    height: 20rem;
    background-position-y: -8rem;
  }
}

.contactButton {
  background-color: #ce9963;
  padding-left: 75px;
  padding-right: 75px;
  border: none;
  font-size: 21px;
  font-weight: bold;
  position: relative;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .contactButton {
    width: inherit;
    margin-right: auto;
  }
}

.addressHeader {
  color: #ce9963;
  font-weight: bold;
  font-size: 1.3rem;
}

.address {
  color: white;
  margin-bottom: 0;
}

@media screen and (max-width: 992px) and (min-width: 361px) {
  .sponsor {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 992px) and (min-width: 361px) {
  .silverSponsor {
    width: 100px;
    height: 100px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 360px) {
  .sponsor {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 360px) {
  .silverSponsor {
    width: 35px;
    height: 35px;
    margin-top: -10px;
  }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* partner */

.merchant {
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  padding: 1rem;
  /* width: 16.5rem; */
  background-color: #ffffff;
  /* justify-content: space-around; */
}

.merchantNull{
    justify-content: center;
    display: flex;
    flex:1;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    color: white
  }
@media screen and (max-width: 992px) and (min-width: 768px) {
  .merchant {
    border-radius: 5px;
    /* width: 11rem; */
  }

  
}
@media screen and (max-width: 767px) {
    .merchant {
      border-radius: 5px;
      padding: 5px;
      /* width: 6rem; */
    }
    .merchantNull{
        font-size: 1rem;
      }
}
