.cardContainer {
    padding-left: 0;
    padding-right: 0;
    text-decoration: none;
}

.cardContainer:hover {
    text-decoration: none;
}

.cardWrapper {
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.pengurusRow.row .eventImage-col {
    margin-bottom: 30px;
}
.eventImage {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
}

.eventList-Container{
    min-height: 450px !important;
}

.event-header {
    margin-bottom: 5px;
}
.event-link {
    color: #CE9963;
    text-decoration: underline;
}
.event-link:hover {
    color: #CE996395;
}

@media screen and (max-width: 992px) and (min-width:768){
    .eventList-Container{
        min-height: 580px !important;
    }
}
h5.titleEvent {
    color: #CE9963;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
}

h5.title{
    color: #CE9963;
}
h5.title.sub{
    margin-top: -0.8rem;
    padding-top: 0;
    font-size: 1rem;
}

@media screen and (max-width: 576px) {
    .event-detail-info h5.title {
        text-align: center;
        margin-top: 10px;
    }
    .galleryContainer .pengurusRow.row {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .galleryContainer .eventImage-col {
        display: contents;
    }
    .galleryContainer .eventImage-col .eventImage {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .homeEventImage {
        display: block;
        width: auto;
        height: 15rem;
        margin-right: auto;
    }
    h5.titleEvent {
        font-size: small;
    }
    .event-desc-container {
        margin-top: -25px;
    }
}

.tanggalEvent {
    color: white;
    font-size: small;
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
    .tanggalEvent {
        color: white;
        font-size: xx-small;
        margin-bottom: 0.1rem;
    }
    
}

.cardEvent {
    background-color: #565656;
}

.cardEvent:hover {
    background-color: #232323;
    text-decoration: normal;
    cursor: pointer;
}

.filter {
    margin-top: 4rem;
    margin-bottom: 1.5rem;
}

.filterButton {
    background-color: #CE9963;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    bottom: 0;
    position: absolute;
    margin-bottom: 1rem;
    width: max-content;
}

@media screen and (max-width: 768px) {
    .filterButton {
        position: unset;
        width: 100%;
    }
}
.filterButton:hover {
    background-color: #CE996395;
    border: none
}

.filterButton:active {
    background-color: #CE996395;
    border: none
}

.filterButton:focus {
    border-style: solid;
    background-color: #CE996395;
}

.eventDetailContainer {
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.galleryContainer {
    height: auto;
    margin-top: 2rem;
    padding-left: 0px;
    padding-right: 0px;
    border-width: 0px;
    border-top-width: 1px;
    border-style: solid;
    border-color: #CE9963;
    width: 100%;
    padding-top: 3rem;
    margin-bottom: 3rem;
}

.eventImage {
    border-radius: 5px;
}

.eventImage:hover {
    opacity: 0.5;
    cursor: pointer;
}

.filterContainer {
    padding-left: 0;
    padding-right: 0
}

.inputProfile {
    color: #CE9963;
}

@media screen and (max-width: 767px) {
    .inputProfile {
        font-size: 0.7rem;
    }
}