.listImage {
  position: absolute;
}
.listText {
  margin-left: 20px;
}

.list {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.policy{
  margin-top: 70px;
}

.about {
  margin-top: 70px;
  background-image: url("/public/Assets/about.jpg");
  height: 600px;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.aboutImage {
  background-image: url("/public/Assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
}
.aboutText {
  background-color: #00000099;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutText2 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.2rem;
}

.visiDanMisi{
  padding-left: 15px;
  padding-right: 15px;
}

p.millionHeader {
  color: #ce9963;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 21;
  display: flex;
  align-items: baseline;
}

p.millionContent {
  color: white;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 21;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .aboutText {
    padding-top: 8rem;
  }

  .aboutText2 {
    font-size: 1rem;
  }

  .list {
    width: 12px;
    height: 12px;
    display: flex;
  }
  p.millionHeader {
    font-size: 0.8rem;
  }
  p.millionContent {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 767px) {
  .aboutText {
    padding-top: 4rem;
  }

  .aboutText2 {
    font-size: 0.8rem;
  }

  .list {
    width: 10px;
    height: 10px;
    display: flex;
  }
  p.millionHeader {
    font-size: 0.7rem;
  }
  p.millionContent {
    font-size: 0.7rem;
  }
}
