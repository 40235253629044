.votingGroup{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.buttonVoting{
    margin-top: 1rem;
}
.cardButton.voting{
    width: 100%;
}
.btn-primary.voting {
    background-color: #ce9963;
}
.btn-primary.voting:hover {
    background-color: #daa774;
}
/* Voting_Com */
.voting .pembinaWrap {
    background-color: transparent;
    margin-bottom: 0.5rem;
}
.voting .pembina {
    opacity: 1;
    filter: brightness(0.5);
}
.voting .pembina,
.voting .pembinaActive {
    width: 100%;
    aspect-ratio: 1/1;
}
.voting .MuiFormControlLabel-root {
    width: 100%;
    margin: 0;
}
.voting .MuiFormControlLabel-root .MuiTypography-root {
    color: #ce9963;
    font-weight: bold;
    font-size: 1rem;
}
.voting .MuiFormControlLabel-root .MuiRadio-root {
    color: #ce9963;
}
/* Voting_Page */
.votingGroup .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-top: 0;
}
.votingGroup .dropdown-voting .input-filter
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
    padding-top: 10px;
    padding-bottom: 10px;
}
.votingGroup .dropdown-voting .input-filter {
    border-radius: .25rem;
}

@media (max-width: 576px) {
    .pembinaWrap {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 480px) {
    .voting .MuiFormControlLabel-root .MuiTypography-root {
        font-size: 0.7rem;
    }
}

@media (min-width: 480px) and (max-width: 1024px) {
    .voting .MuiFormControlLabel-root .MuiTypography-root {
        font-size: 0.8rem;
    }
}