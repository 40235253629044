/* Navbar */

.navMenu {
  color: white !important;
  transition: 0.3s ease-in-out;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -ms-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
}

.navMenu:hover {
  color: #ce9963 !important;
  background-color: #343a40;
}

.navbar {
  width: 100%;
  height: 75px;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: 0.3s ease-in-out;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -ms-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
}
.nav-link {
  color: #ffffff !important;
}
.nav-link:hover {
  color: #ce9963 !important;
}
.navbar-title {
  color: #ce9963 !important;
}
.smaller {
  height: 5em;
  transition: 0.3s ease-in-out;
  -webkit-transition: ease-in-out 0.3s;
  -moz-transition: ease-in-out 0.3s;
  -ms-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
}
.smaller .navShrink {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.logoMobile {
  display: none;
}

.logoDesktop {
  display: flex;
  align-self: center;
  align-items: center;
  position: relative;
}
.collapse.show {
  top: 60px;
  width: 100%;
}
.collapseBG {
  background-color: #343a40;
}

.burgerButton {
  position: absolute;
  left: 1rem;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.dropdown-menu {
  background-color: #343a40;
  border: none;
  padding-top: 0;
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 992px) {
  .logoMobile {
    display: flex;
    align-self: center;
    align-items: center;
  }
  .logoDesktop {
    display: none;
  }
}

/* footer */

.footer {
  padding-left: 60px;
  padding-right: 60px;
  background-color: #343a40;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
}

.footerText {
  color: white;
  margin-bottom: 0px;
}

.list-footer .headerFooterText:hover {
  color: #cc9d6e;
}
.list-footer a {
  text-decoration: none;
}
.list-footer .headerFooterText {
  color: #ce9963;
  margin-bottom: 0px;
  font-weight: bolder;
}
.list-footer li {
  color: white;
}
.contact-container {
  display: flex;
  flex-direction: column;
}

.medsosIcon {
  width: 0.9rem;
  position: relative;
  margin-top: -3;
  margin-right: 0.3rem;
  margin-left: auto;
}

.copyright {
  display: flex;
  justify-content: flex-start;
  color: #ce9963;
  font-weight: bolder;
  margin-bottom: 0;
}

.supportedBy {
  display: flex;
  font-weight: bolder;
  justify-content: flex-start;
  color: #ce9963;
  margin: 0;
}

.supportedImg {
  width: 70%;
  margin-right: 0;
  margin-bottom: 0;
}

.otherLink:hover {
  text-decoration: none;
}

@media screen and (max-width: 1320px) and (min-width: 992px) {
  .nav-link,
  .navMenu {
    font-size: 12px;
  }
  .smaller .navShrink {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

@media screen and (max-width: 992px) and (min-width: 640px) {
  .footerText {
    margin-right: auto;
    margin-left: auto;
    font-size: 0.8rem;
  }
  .copyright {
    font-size: 0.7rem;
  }
  .supportedImg {
    width: 45%;
  }
  .collapseBG {
    position: absolute;
    top: 60px;
    width: 100%;
  }
  .smaller .navShrink {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .smaller {
    height: 75px;
  }
}
@media screen and (max-width: 767px) {
  .headerFooterText {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
    text-align: center;
  }
  .footerText {
    margin-right: auto;
    margin-left: auto;
    font-size: 0.7rem;
    margin-bottom: 10px;
    text-align: center;
  }
  .copyright {
    font-size: 0.6rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .media-sosial {
    width: max-content;
    margin: 5px auto;
  }
  .contact-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .contact-value {
    align-self: center;
  }
  .supportedBy {
    display: flex;
    justify-content: center;
  }
  .supportedImg {
    width: 60%;
    /* margin-top: 0.5rem; */
    margin-right: auto;
    margin-left: auto;
  }
  .collapseBG {
    position: absolute;
    top: 60px;
    width: 100%;
  }
}

/* Testing Animation */
@keyframes uparrow {
  0% {
    transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    transform: translateY(-0.4em);
    opacity: 0.9;
  }
}
.arrow {
  border-color: transparent;
  border-style: solid;
  position: absolute;
  bottom: -40px;
  border-width: 0 22.5px;
  display: block;
  height: 0;
  opacity: 0.4;
  text-indent: -9999px;
  transform-origin: 50% 50%;
  width: 0;
}
.up {
  animation: uparrow 0.6s infinite alternate ease-in-out;
  border-bottom: 30px solid #ce9963;
}
