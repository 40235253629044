.sponsor-home .logo-card{
    display: flex !important;
    align-items: center;
    height: 200px;
}
.logo-card img{
    width: 80%;
    object-fit: cover;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
}