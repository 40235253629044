.card-event {
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.card-event:hover .card-media {
  transform: scale(1.05);
}
.card-content {
  position: relative;
  z-index: 99;
}
.date-mask {
  padding: 15px;
  margin-right: 15px;
  height: 100%;
  background-color: #ce9963;
  text-align: center;
  border-bottom-right-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  color: black;
  line-height: 30px;
  font-weight: bold;
}
.date-mask p {
  margin: 0;
}
.card-text-container {
  padding-top: 15px;
  line-height: 25px;
  text-transform: capitalize;
}

.card-content-event .card-title-event {
  margin: 0;
  text-align: left;
  text-transform: capitalize;
  font-size: 14px;
  padding: 10px;
  padding-bottom: 0;
}

.card-text-container h5 {
  font-size: 20px;
}

.card-text-container h5,
.card-text-container p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card-event .MuiCardContent-root {
  height: 100%;
}

.custom-pagination {
  display: flex;
  width: max-content;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.custom-pagination .pagination-input .MuiInputBase-input {
  padding: 15px;
  width: 40px;
  text-align: center;
}
.pagination-input.MuiFormControl-root {
  background-color: white;
  border-radius: 8px;
}
.custom-pagination .total-page {
  color: white;
  margin-left: 10px;
  margin-top: 10px;
}
.custom-pagination .pagination-arrow {
  color: #CE9963;
  cursor: pointer;
  pointer-events: default;
}
.custom-pagination .pagination-arrow.disabled {
  color: #aaa299;
  cursor: initial;
  pointer-events: none;
}
.custom-pagination .pagination-arrow:hover {
  color: burlywood;
}

@media (max-width: 552px) {
  .date-mask {
    font-size: 12px;
    height: unset;
    padding: 10px;
    border-bottom-right-radius: 25%;
  }

  .card-text-container {
    font-size: 12px;
    padding-top: 5px;
    line-height: 15px;
  }
  .card-text-container h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .custom-pagination .pagination-arrow {
    width: 0.8em;
  }
}

@media (min-width: 960px) {
  .date-mask {
    font-size: 12px;
    padding-left: 10px;
  }
}