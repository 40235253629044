.formContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}

.form-label {
  font-weight: bold;
  color: #ce9963;
}

.form-check {
  align-items: center;
  justify-content: center;
  color: white;
}

.error {
  border-color: red;
}

.regisButton {
  background-color: #ce9963;
  width: 15rem;
  border: none;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  align-self: baseline;
}

.regisButton:hover {
  background-color: #ce996395;
  border: none;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  border: 3px solid red;
}
.errorText {
  font-size: small;
  color: red;
}
.cXcRCo {
  z-index: 9999;
}

.merchantFormWrapper {
  background-image: url("/public/Assets/bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 60px;
}

@media screen and (max-width: 992px) {
  .textLabel {
    font-size: 0.9rem;
  }
}

#verif_label{
  width: 50%;
  margin: auto;
  margin-top: 2rem;
}
#verif_input{
  width: 50%;
  margin-left: auto;
}
#verif_button{
  width: auto;
  margin-right: auto;
  background-color: #ce9963;
  color: white;
  border: none;
  box-shadow: none;
  font-weight: bold;
  margin-bottom: 2rem;
}

/* --------------------------------- NEW REGISTRATION FORM STYLE --------------------------------- */

/* Style for form layout */
.MuiGrid-container.grid-container {
  padding-top: 125px;
  padding-bottom: 50px;
}
.add-form {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
}
.full-width {
  width: 100%;
}
.add-margin-bottom {
  margin-bottom: 20px !important;
}
.MuiButton-root.add-new-btn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #CE9963;
  color: white;
  font-weight: bold;
}
.MuiButton-root.add-new-btn:hover {
  background-color: burlywood;
}

/* Error style for required field */
.text-required {
  color: red;
  margin-top: -22px;
  text-align: left;
  margin-bottom: 1.2rem;
}
.text-required-half {
  color: red;
  margin-top: -17px;
  float: left;
  margin-bottom: 1.2rem;
}
.text-required-half.half-right {
  margin-left: 5%;
  margin-bottom: 1.2rem;
}

/* Style for error on validation */
.error-style .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}
.error-style .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

/* Style for half-width input field */
.form-left-field {
  width: 97%;
  float: left;
  margin-bottom: 20px !important;
}
.form-right-field {
  width: 97%;
  float: right;
  margin-bottom: 20px !important;
}

/* Style for selection label */
.tf-label label {
  padding: 0 3px;
  background-color: white;
}

/* Style for image uploader */
.browse-btn {
  width: 38%;
  display: inline-block;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  border: solid 1px darkgray;
  background-color: #CE9963;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.browse-btn:hover {
  background-color: burlywood;
}
.img-name {
  width: 62%;
  display: inline-grid;
  padding: 10px 5px;
  border: solid 1px darkgray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.image-info {
  padding: 15px 15px;
}
.delete-img-btn {
  position: relative;
}
.delete-img-btn .MuiSvgIcon-root {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #F0F0F0;
}
.can-delete {
  display: initial !important;
  cursor: pointer;
}
.cannot-delete {
  display: none !important;
  cursor: default;
}

/* Style for full width input image */
.full-input-image .image-info {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}

/* Style for grouped field */
.grouped-field [class*="control"] {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.grouped-field [class*="control"]:hover {
  border-color: #592c0d !important;
}
.grouped-field [class*="placeholder"] {
  color: #592c0d77;
}
.grouped-field [class*="control"] [class*="ValueContainer"] [class*="multiValue"] {
  border-radius: 15px;
  padding: 3px 6px;
  font-size: 0.9rem;
}
.grouped-field [class*="menu"] {
  z-index: 101;
}

.grouped-label {
  font-size: 0.78rem;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  margin-top: -10px;
  position: absolute;
  margin-left: 7px;
  padding: 0 6px;
  left: 0;
  z-index: 100;
}
.grouped-label.half-right-label {
  margin-left: 1.5rem;
}
.hide-label {
  display: none;
}

/* Style for disabled dropdown field */
.disabled-field {
  background: none !important;
}
.disabled-field [class*="control"] {
  cursor: default;
  pointer-events: none;
}
.disabled-field:hover, .disabled-field [class*="control"]:hover {
  border-color:rgba(0, 0, 0, 0.22) !important;
}
.disabled-field [class*="multiValue"] svg,
.disabled-field [class*="chipContainer"] .MuiChip-deleteIcon {
  display: none;
}

/* Style for radio button & choicebox */
.rb-layout, .cb-layout {
  border: solid 1px;
  border-color: rgba(0, 0, 0, 0.28);
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.rb-layout:hover, .cb-layout:hover {
  border-color: black;
}
.rb-label, .cb-label {
  display: contents !important;
  font-size: 0.8em !important;
}
.cb-layout .MuiGrid-item .MuiFormControlLabel-root {
  display: block;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root {
  cursor: default;
  pointer-events: none;
}
.cb-item .MuiFormGroup-root .MuiFormControlLabel-root .MuiButtonBase-root {
  pointer-events: initial;
}
.MuiCheckbox-colorSecondary.Mui-disabled {
  background: none !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #CE9963 !important;
}

/* Style for switch / toggle form */
.MuiFormControl-root.switch-layout {
  border: solid 1px;
  border-color: rgba(0, 0, 0, 0.28);
  border-radius: 5px;
  padding: 8px 0;
  width: 100%;
}
.MuiFormControl-root.switch-layout:hover {
  border-color: black;
}
.MuiFormLabel-root.legend-switch {
  margin-left: 10px;
  font-size: 0.8em;
}
.MuiFormControlLabel-root.fcl-switch {
  padding-left: 10px;
}

/* Style for input image conditions */
.input-img-terms {
  margin-top: 5px;
}

/* Form Title */
.form-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}

/* Clothes Image Reference */
.clothes-image {
  max-width: 450px !important;
  width: 50%;
  display: block;
  margin: 0 auto 2rem;
}

.verification-field.MuiFormControl-root {
  width: max-content;
  float: right;
  margin-right: 3%;
}
.verification-field.MuiFormControl-root .MuiInputBase-input {
  padding: 14px 14px 10px;
}
#verification-btn {
  margin-left: 3%;
  padding: 8px 16px;
  font-weight: bold;
  color: white;
  background-color: #CE9963;
}
#verification-btn:hover {
  background-color: burlywood;
}

@media (max-width: 575px) {
  #verif_input, #verif_button {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .verification-field.MuiFormControl-root {
    width: 100%;
  }
  #verification-btn {
    width: 97%;
    font-size: 10.5px;
    padding: 11px 12px;
  }
}
@media (max-width: 348px) {
  #verification-btn {
    font-size: 10px;
    padding: 11.5px 0px;
  }
}

@media (max-width: 480px) {
  .clothes-image {
    width: 100%;
  }
  .content.form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .add-form {
      padding: 1rem 1.5rem 3rem;
  }
  .form-right-field, .form-left-field {
      width: 100%;
  }
  .full-input-image .image-info {
      width: 100%;
  }
  .browse-btn {
      width: 35%;
  }
  .text-required-half.half-right {
      margin-left: 0;
  }
  .grouped-label.half-right-label {
      margin-left: 0.5rem;
  }
  [class*="PrivateRadioButtonIcon-root"] .MuiSvgIcon-root {
      font-size: 1rem;
  }
  .rb-layout .MuiFormControlLabel-root [class*="MuiTypography-body"] {
      font-size: 0.8rem;
  }
  .cb-item .MuiFormGroup-root .MuiFormControlLabel-root [class*="PrivateSwitchBase-root"] {
      padding-right: 5px;
  }
  .cb-item .MuiFormGroup-root .MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root {
      font-size: 1.1rem;
  }
  .cb-layout .MuiFormControlLabel-root [class*="MuiTypography-body"] {
      font-size: 0.8rem;
  }
  .cb-layout div:nth-child(2) {
      padding-left: 0 !important;
  }
  .custom-form .grouped-label.half-right-label {
      margin-left: 1.8rem;
  }
  .add-btn-table.MuiButton-root {
      background-color: #CE9963;
  }
  .subscription-year-layout {
      margin-bottom: 1rem;
  }
  .MuiGrid-item.vote-result-layout h3 {
      margin: 0 10px;
      font-size: 1rem;
  }
  .MuiGrid-item.vote-result-layout h1 {
      font-size: 2rem !important;
      margin-top: 1rem !important;
  }
  .edit-btn.MuiButton-root {
      float: none;
      display: block;
      margin: -5px auto 2rem !important;
      min-width: 100%;
  }
  .secondary-btn.MuiButton-root {
      margin-bottom: 1.5rem !important;
  }
  .responsive-submit-btn.MuiButton-root {
      float: left !important;
      margin-right: 0 !important;
  }
  .input-img-terms {
      text-align: center;
  }
  .center-responsive {
      text-align: center;
  }
  .MuiButton-root.add-new-btn.member {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .clothes-image {
    width: 80%;
  }
  .content.form {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .add-form {
      padding: 1rem 2rem 3rem;
  }
  .form-right-field, .form-left-field {
      width: 100%;
  }
  .full-input-image .image-info {
      width: 80%;
  }
  .browse-btn {
      width: 30%;
  }
  .text-required-half.half-right {
      margin-left: 0;
  }
  .cb-layout div:nth-child(2) {
      padding-left: 0 !important;
  }
  .edit-btn.MuiButton-root {
      float: none;
      display: block;
      margin: -5px auto 2rem;
  }
  .responsive-submit-btn.MuiButton-root {
      float: left !important;
  }
  .add-btn-table.MuiButton-root {
      background-color: #CE9963;
  }
  .edit-btn.MuiButton-root {
      float: none;
      display: block;
      margin: -5px auto 2rem !important;
      min-width: 100%;
  }
  .secondary-btn.MuiButton-root {
      margin-bottom: 1.5rem !important;
  }
  .responsive-submit-btn.MuiButton-root {
      float: left !important;
      margin-right: 0 !important;
  }
  .input-img-terms {
      text-align: center;
  }
  .center-responsive {
      text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .clothes-image {
    width: 75%;
  }
  .content.form {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .add-form {
      padding: 1rem 2rem 3rem;
  }
  .form-right-field, .form-left-field {
      width: 100%;
  }
  .full-input-image .image-info {
      width: 80%;
  }
  .browse-btn {
      width: 30%;
  }
  .text-required-half.half-right {
      margin-left: 0;
  }
  .cb-layout div:nth-child(2) {
      padding-left: 0 !important;
  }
  .add-btn-table.MuiButton-root {
      background-color: #CE9963;
  }
  .edit-btn.MuiButton-root {
      float: none;
      display: block;
      margin: -5px auto 2rem !important;
      min-width: 100%;
  }
  .secondary-btn.MuiButton-root {
      margin-bottom: 1.5rem !important;
  }
  .responsive-submit-btn.MuiButton-root {
      float: left !important;
      margin-right: 0 !important;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .clothes-image {
    width: 65%;
  }
  .full-input-image .image-info {
      width: 80%;
  }
  .browse-btn {
      width: 30%;
  }
  .grouped-label.half-right-label {
      margin-left: 1.2rem;
  }
  .img-name {
      width: 60%;
  }
  .rb-layout .MuiGrid-item label {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .rb-layout div:nth-child(2) .MuiFormControlLabel-root {
      margin-left: 10px !important;
  }
  [class*="PrivateRadioButtonIcon-root"] .MuiSvgIcon-root {
      font-size: 1.1rem;
  }
  .cb-item .MuiFormGroup-root .MuiFormControlLabel-root [class*="PrivateSwitchBase-root"] {
      padding-right: 5px;
  }
  .cb-item .MuiFormGroup-root .MuiButtonBase-root .MuiIconButton-label .MuiSvgIcon-root {
      font-size: 1.1rem;
  }
  .cb-layout .MuiFormControlLabel-root [class*="MuiTypography-body"] {
      font-size: 0.9rem;
  }
  .edit-btn.MuiButton-root {
      margin-top: 1.8rem;
      font-size: 0.8rem;
      padding: 5px 10px;
  }
  .add-btn-table.MuiButton-root {
      font-size: smaller;
  }
}

@media (min-width: 1921px) {
  .add-form {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}