.new-container {
    width: 1024px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.register-content {
    width: 40%;
    margin-top: 1.5rem;
}
.register-quotes {
    color: white;
    font-weight: initial;
    font-size: 24px;
    margin-top: 90px;
}
.qr-app, .btn-app {
    width: 80%;
    height: auto;
    display: block;
    margin-left: auto;
}
.qr-section {
    width: 30%;
    padding: 20px;
    padding-top: 40px;
}
.btn-app {
    width: 60%;
    margin-top: 30px;
    margin-right: 25px;
}
.btn-app:hover {
    cursor: pointer;
    filter: brightness(1.5);
}
.vertically {
    position: absolute;
    top: calc(50% - 70px);
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.footer-register .footer {
    height: 200px;
}
.bgImage.register {
    padding-top: 70px;
    padding-bottom: 200px;
}
.bg.register {
    height: 100vh;
}

@media (max-width: 767px) and (min-height: 375px) {
    .register-quotes {
        text-align: center;
        margin-top: 0;
        padding: 0 15px;
    }
    .btn-app {
        margin-top: 15px;
        margin-bottom: 10px;
    }
}

@media (max-width: 320px) {
    .register-quotes {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .new-container, .register-content, .qr-section {
        display: block;
        width: 100%;
    }
    .register-quotes {
        font-size: 18px;
    }
    .qr-app, .btn-app {
        margin-right: auto;
    }
    .vertically {
        position: initial;
        transform: initial;
    }
    .footer-register .footer, .bg.register {
        height: auto;
    }
}

@media (max-height: 480px) {
    .new-container {
        padding: 3rem;
    }
    .register-quotes {
        margin-top: 0;
        margin-bottom: 3rem;
    }
    .qr-section {
        padding: 0;
        margin-bottom: 3rem;
    }
    .qr-app, .btn-app {
        width: 100%;
        margin-top: 2rem;
    }
}

@media (min-height: 600px) and (orientation: landscape) {
    .footer-register {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}

@media (min-width: 1024px) and (max-width: 1360px) {
    .register-quotes {
        font-size: 20px;
        margin-top: 50px;
    }
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .new-container {
        width: 100%;
        padding: 2rem;
    }
    .register-quotes {
        font-size: 20px;
        margin-top: 0;
        font-size: 16px;
    }
    .qr-section {
        padding: 0;
    }
    .footer-register {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .new-container {
        width: 80%;
    }
    .qr-section {
        padding-right: 5px;
    }
    .qr-app {
        margin-right: 0;
    }
    .btn-app {
        margin-right: 20px;
    }
}

/* LAPTOP */
/* @media (min-width: 1280px) and (max-width: 1360px) and (orientation: landscape) {
    .vertically {
        top: 23vh;
    }
}
@media (min-width: 1361px) and (max-width: 1440px) and (orientation: landscape) {
    .vertically {
        top: 25vh;
    }
}
@media (min-width: 1441px) and (max-width: 1600px) and (orientation: landscape) {
    .vertically {
        top: 27vh;
    }
}
@media (min-width: 1601px) and (max-width: 2000px) and (orientation: landscape) {
    .vertically {
        top: 30vh;
    }
} */